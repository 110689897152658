.container {
    /* border: 1px solid #E0E0E0;*/
}

.amountBox {
    background-color: var(--lightBlue);
}

.deniedBox {
    background-color: var(--red);
    color: var(--white);
    margin: 0vh auto 25vh;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;

}

.icon {
    top: 15%;
    left: 7%;
}

.space {
    margin: 0 0.5vw 0 0;
}
.ltr .space {
    margin: 0 0 0 0.5vw;
}

@media (max-width: 480px) {
    .space {
        margin: 0 1vw 0 0;
    }
    .ltr .space {
        margin: 0 0 0 1vw;
    }
}